import { useSelector } from 'react-redux';
import store from '../store';
import { globalUpdateDialogId } from '../globalSlice';
import { getIsSaveEnabled } from './outlinesSlice';
import BannerOpsButton from '../banner/BannerOpsButton';
import OutlinesDialogSaveAsk from './OutlinesDialogSaveAsk';
import { OUTLINES_SAVE_ASK_DIALOG_ID } from './outlinesPageHelpers';

const updateDialogIdAction = globalUpdateDialogId(OUTLINES_SAVE_ASK_DIALOG_ID);
const handleShowShareAskDialogClick = () => {
  store.dispatch(updateDialogIdAction);
};

function OutlinesBannerOpsSave() {
  const isSaveEnabled = useSelector(getIsSaveEnabled);

  return (
    <>
      <BannerOpsButton
        disabled={!isSaveEnabled}
        icon="share"
        title="Save and share your map"
        onClick={handleShowShareAskDialogClick}
      />
      {isSaveEnabled ? <OutlinesDialogSaveAsk /> : null}
    </>
  );
}

export default OutlinesBannerOpsSave;
