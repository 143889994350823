import { PageContext } from '../PageContext';
import MfCanonical from '../util/MfCanonical';
import { BannerLayout } from '../banner/BannerLayout';
import { BodyLayout } from '../BodyLayout';
import { MfName } from '../util/MfName';

// image imports for Parcel...
// from: https://commons.wikimedia.org/wiki/File:Faenza-applications-development.svg
const underConstructionUrl = new URL('./under-construction.svg', import.meta.url);

const hrStyle = { margin: '3px 11px' };

export function IsollPage() {
  return (
    <PageContext.Provider value="Iso-Lat/Lon">
      <MfCanonical pathname="isoll" />
      <BannerLayout />
      <BodyLayout>
        <hr style={hrStyle} />
        <div className="mf-isoll-body">
          <img src={underConstructionUrl} alt="Under Construction" />
          <h2> Iso-Lat/Lon feature is under construction </h2>
          <p>
            With apologies, <MfName /> does not presently support the Iso-Lat/Lon feature. I hope to
            have this implemented someday in the future.{' '}
          </p>
          <p>— Kelvin Thompson, March 2025</p>
        </div>
      </BodyLayout>
    </PageContext.Provider>
  );
}
