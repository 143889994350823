import { PageContext } from '../PageContext';
import MfCanonical from '../util/MfCanonical';
import { BannerLayout } from '../banner/BannerLayout';
import { BodyLayout } from '../BodyLayout';
import { WallToWallBannerOps } from './WallToWallBannerOps';
import { WallToWall } from './WallToWall';

export function WallToWallPage() {
  return (
    <PageContext.Provider value="Wall to Wall">
      <MfCanonical pathname="w2w"/>
      <BannerLayout>
        <WallToWallBannerOps />
      </BannerLayout>
      <BodyLayout>
        <WallToWall />
      </BodyLayout>
    </PageContext.Provider>
  );
}
