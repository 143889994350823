import React from 'react';
import { BannerLogo } from '../banner/BannerLogo';

export function BannerLayout({ children }) {
  return (
    <div className="mf-banner">
      {children}
      <BannerLogo />
    </div>
  );
}
