import { useCallback, Fragment } from 'react';
import store from '../store';
import BannerOpsButton from './BannerOpsButton';
import SiteInfoDialog from '../SiteInfoDialog';
import { globalUpdateDialogId } from '../globalSlice';

// TODO: rename to 'BannerOpsSiteInfo'
function BannerOpsButtonSiteInfo({ dialogId, children }) {
  const handleShowInfoDialogClick = useCallback(() => {
    const action = globalUpdateDialogId(dialogId);
    store.dispatch(action);
  }, [dialogId]);

  return (
    <Fragment>
      <BannerOpsButton
        icon="info-sign"
        title="Information about this site"
        onClick={handleShowInfoDialogClick}
      />
      <SiteInfoDialog dialogId={dialogId}>{children}</SiteInfoDialog>
    </Fragment>
  );
}

export default BannerOpsButtonSiteInfo;
