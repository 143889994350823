import { useMap } from '../useMap';
import { SearchBox } from '../SearchBox';

// const LAYER_ID = 'wall-to-wall';

export function WallToWall() {
  const map1 = useMap({
    mapNum: 1,
    parentDivId: 'mf-walltowall',
    // onAdded: handleMapAdded,
    // onRemove: handleMapRemove,
  });

  return (
    <>
      <SearchBox map={map1} />
      <div id="mf-walltowall" className="mf-map-active" />
    </>
  );
}
