import { useContext, useState, useCallback } from 'react';
import { Button } from '@blueprintjs/core';
import MfDialog from '../util/MfDialog';
import MfCopyableLink from '../util/MfCopyableLink';
import { fetchPhpContent } from '../util/helpers';
import {
  OutlinesContext,
  getMfTiles,
  getOutlinesLayerGroup,
  OUTLINES_SAVE_ASK_DIALOG_ID,
} from './outlinesPageHelpers';

const STATUS = {
  INITIAL: 'INITIAL',
  GENERATING_SUBMISSION: 'GENERATING_SUBMISSION',
  AWAITING_BACKEND: 'AWAITING_BACKEND',
  SHOWING_SAVE_FAILED: 'SHOWING_SAVE_FAILED',
  SHOWING_SAVE_RESULT: 'SHOWING_SAVE_RESULT',
};

const genXmlForMap = (mapNum, map, mfTiles) => {
  const mapCenter = map.getCenter();
  const { lat, lng } = mapCenter;
  const mapZoom = map.getZoom();
  const dbMapType = mfTiles.getCurrentDbMapType();
  return `<map num='${mapNum}' lat='${lat}' lng='${lng}' zoom='${mapZoom}' type='${dbMapType}' />\n`;
};

const getRowIdFromXmlDocument = xmlDocument => {
  const errorResult = {
    error: 'Unfortunately, an error occurred attempting to retrieve the saved map.',
    rowIdStr: '',
  };

  if (!xmlDocument || !(xmlDocument instanceof XMLDocument)) {
    return errorResult;
  }

  const { documentElement } = xmlDocument;
  const rowIdCollection = documentElement.getElementsByTagName('rowid');
  if (rowIdCollection.length < 1) {
    return errorResult;
  }

  const rowIdElement = rowIdCollection.item(0);
  const rowIdStr = rowIdElement?.getAttribute('value');
  if (!rowIdStr) {
    return errorResult;
  }

  return {
    error: '',
    rowIdStr,
  };
};

function OutlinesDialogSaveAsk() {
  const [status, setStatus] = useState(STATUS.INITIAL);
  const [userMessage, setUserMessage] = useState('(no result yet)');
  // const isSaveEnabled = useSelector(getIsSaveEnabled);

  const { map1, map2 } = useContext(OutlinesContext);
  const mfTiles1 = getMfTiles(map1); // TODO: can these just go inside handleMakeUrlClick()?
  const mfTiles2 = getMfTiles(map2);

  const outlines1LayerGroup = getOutlinesLayerGroup(map1);
  const outline1List = outlines1LayerGroup?._mfMetadata?.outlineList;

  const handleMakeUrlClick = useCallback(() => {
    setStatus(STATUS.GENERATING_SUBMISSION);
    const outlineListXml = outline1List.genXml();
    const map1Xml = genXmlForMap(1, map1, mfTiles1);
    const map2Xml = genXmlForMap(2, map2, mfTiles2);
    const mapsXml = `<maps>\n${map1Xml}${map2Xml}</maps>\n`;
    const persistXml = `<save>\n${outlineListXml}${mapsXml}</save>\n`;

    setStatus(STATUS.AWAITING_BACKEND);
    setUserMessage('(awaiting completion…)');
    const fetchPromise = fetchPhpContent('mover_make.php', `xml=${persistXml}`);
    fetchPromise.then(responseXml => {
      const { error, rowIdStr } = getRowIdFromXmlDocument(responseXml);
      if (error || !rowIdStr) {
        setStatus(STATUS.SHOWING_SAVE_FAILED);
        setUserMessage(error);
      } else {
        setStatus(STATUS.SHOWING_SAVE_RESULT);
        const urlWithQueryString = `${location.origin}/outlines?show=${rowIdStr}`;
        history.replaceState({}, '', urlWithQueryString);
        setUserMessage(urlWithQueryString);
      }
    });
  }, [map1, map2, outline1List, mfTiles1, mfTiles2, setStatus, setUserMessage]);

  return (
    <MfDialog
      dialogId={OUTLINES_SAVE_ASK_DIALOG_ID}
      className="mf-outlines-dialog-share"
      icon="share"
      title="Share Your Map"
    >
      <p>Do you want to share this map?</p>
      <p>
        Click the button below to generate a URL for this map.
        <br />
        You can share the URL with your friends!
      </p>

      <Button
        id="persistButton"
        disabled={status !== STATUS.INITIAL}
        intent="primary"
        title="Generate permanent URL for this map"
        className="ui-btn ui-btn-inline ui-mini ui-corner-all"
        onClick={handleMakeUrlClick}
      >
        Make URL
      </Button>

      <div className="mf-outlines-result-url">
        <p className="mf-italic">Generated URL:</p>
        {status !== STATUS.SHOWING_SAVE_RESULT && <p className="mf-pending">{userMessage}</p>}
        {status === STATUS.SHOWING_SAVE_RESULT && <MfCopyableLink url={userMessage} />}
      </div>
    </MfDialog>
  );
}

export default OutlinesDialogSaveAsk;
