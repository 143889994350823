import { useContext } from 'react';
import { PageContext } from '../PageContext';
import { MfName } from '../util/MfName';
import { PageChooser } from './PageChooser';

export function BannerLogo() {
  const pageName = useContext(PageContext);

  return (
    <div className="mf-banner-area mf-banner-logo">
      <div className="mf-banner-logo-container">
        <MfName />
        <div className="logo-page-name">{pageName}</div>
        <PageChooser />
      </div>
    </div>
  );
}
