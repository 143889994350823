import { BannerOps } from '../banner/BannerOps';
import BannerOpsButtonSiteInfo from '../banner/BannerOpsButtonSiteInfo';
import MfInfoSection from '../util/MfInfoSection';
import MfNameInText from '../util/MfNameInText';

export const W2W_SITE_INFO_DIALOG_ID = 'w2wSiteInfoDialog';

// FUTURE: {/* <WallToWallBannerControls /> */

export function WallToWallBannerOps() {
  return (
    <BannerOps>
      <BannerOpsButtonSiteInfo dialogId={W2W_SITE_INFO_DIALOG_ID}>
        <MfInfoSection title="Wall to Wall">
          <p>
            This “page” of <MfNameInText /> simply presents an interactive map filling most of your
            browser window.
          </p>
        </MfInfoSection>
      </BannerOpsButtonSiteInfo>
    </BannerOps>
  );
}
